import React from 'react'
import { graphql } from 'gatsby'
import { LP } from '../../pages/products/bare-metal-cloud'
export default function (props) {
  return <LP {...props} />
}
export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "Bare Metal Cloud" } } }
        author: {}
      }
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          wp_date
          created_at
          content
          title
          thumbnail {
            localFile {
              publicURL
            }
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "bmc" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
